<template>
     <CForm ref="form" class="needs-validation"   >
        <CRow>
            <CCol col="6" >
                <CInput
                    label="Kod"
                    horizontal
                    type="text"
                    autocomplete="text"
                    v-model="data.code"
                    :minlength="6"
                    :maxlength="14"
                >
                </CInput>
            </CCol>
            <CCol col="6" >
                <CInput
                    label="Max Kullanım"
                    horizontal
                    type="text"
                    autocomplete="text"
                    v-model="data.maxCount"
                >
                </CInput>
            </CCol>
        </CRow>
      
        <CRow>
            <CCol col="6" >
                <CInput
                    label="Başlangıç Tarihi"
                    type="date"
                    horizontal
                    v-model="data.validDateStart"
                >
                </CInput>
            </CCol>
          <CCol col="6" >
            <CInput
                label="Bitiş Tarihi"
                type="date"
                horizontal
                v-model="data.validDateEnd"
            />
          </CCol>
        </CRow>

        <CRow form class="form-group">
            <CCol tag="label" sm="2" class="col-form-label">
                <b>OtoPromo</b>
            </CCol>
            <CCol sm="2" style="margin-left: -15px">
                <CSwitch
                    class="mr-1"
                    size="lg"
                    color="primary"
                    :checked.sync="data.autoPromoFlag"
                />
            </CCol>
          <CCol tag="label" sm="2" class="col-form-label">
            <b>Geçerli Email</b>
          </CCol>
          <CCol sm="2" style="margin-left: -15px">
            <CSwitch
                class="mr-1"
                size="lg"
                color="primary"
                :checked.sync="data.validEmailFlag"
            />
          </CCol>
        </CRow>

        <CRow>
            <CCol col="6" >
                <CInput
                    label="Api Türü"
                    horizontal
                    type="text"
                    autocomplete="text"
                    v-model="data.apiType"
                />
            </CCol>
            <CCol col="6" >
                <CInput
                    label="Api Kul. Adı"
                    horizontal
                    type="text"
                    autocomplete="text"
                    v-model="data.apiUsername"
                />
            </CCol>
        </CRow>
       
        <CRow>
            <CCol col="6" >
                <CInput
                    label="Api Şifre"
                    horizontal
                    type="password"
                    autocomplete="password"
                    v-model="data.apiPassword"
                />
            </CCol>
            <CCol col="6" >
                <CInput
                    label="Api Öncelik"
                    horizontal
                    type="text"
                    autocomplete="text"
                    v-model="data.apiPriority"
                />
            </CCol>
        </CRow>

       <CRow>
         <CCol col="6" >
           <CInput
               label="Geçerli Domain"
               horizontal
               type="text"
               autocomplete="text"
               v-model="data.validDomain"
           />
         </CCol>
         <CCol col="6" >
           <CInput
               label="Açıklama"
               horizontal
               type="text"
               autocomplete="text"
               v-model="data.description"
           />
         </CCol>
       </CRow>
    </CForm>
</template>

<script>
    export default{
        name: "PromotionForm",
        props:{
            validated: Boolean,
            params: Object,
            actionType: String,
        },
        watch:{
            params: function(val){
                this.data = {...val};
            },
            actionType:  function(val){
                if(val == 'create')
                    this.data = {}
            },

        },
        data: function(){
            return{
                data: this.params ? {...this.params} : {},
            }
        },
    }
</script>